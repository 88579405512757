.test1 {
    width: 150px;
    height: 150px;
    background-color: #FFFF00;
  }
  .test2 {
    width: 150px;
    height: 150px;
    background-color: #ff0000;
  }
  .test3 {
    width: 150px;
    height: 150px;
    background-color: #800080;
  }